import request from '../../request';

const serviceName = '/smart-site/jiangsu/screen/green';

// 预警/报警趋势
export function getGreenWarningTrend(projectId, days) {
  return request(
    `${serviceName}/dust/type/trend/count?projectId=${projectId}&days=${days}`,
    {
      method: 'GET',
    },
  );
}

// 扬尘监测值趋势
export function getGreenDustTrend(projectId, days) {
  return request(
    `${serviceName}/dust/monitor/trend?projectId=${projectId}&days=${days}`,
    {
      method: 'GET',
    },
  );
}

// 扬尘监测数据
export function getGreenDustData(projectId, deviceId) {
  return request(
    `${serviceName}/dust/monitor?projectId=${projectId}&deviceId=${deviceId}`,
    {
      method: 'GET',
    },
  );
}

// 扬尘预警-报警统计
export function getGreenDustStatistics(projectId, days) {
  return request(
    `${serviceName}/dust/subType/count?projectId=${projectId}&days=${days}`,
    {
      method: 'GET',
    },
  );
}

/**
 * 预警/报警信息分页列表
 * @param {object} body
 * @param {'ERROR' | 'WARN'} body.type
 * @param {object} body.query
 * @param {string} body.projectId
 * @returns
 */
export function queryGreenWranInfo(body) {
  return request(`${serviceName}/dust`, {
    method: 'POST',
    body,
  });
}

// 喷淋记录
export function querySprayList(body) {
  return request(`${serviceName}/spray`, {
    method: 'POST',
    body,
  });
}

// 喷淋分析
export function getGreenSprayAssay(projectId, days) {
  return request(
    `${serviceName}/spray/count?projectId=${projectId}&days=${days}`,
    {
      method: 'GET',
    },
  );
}

/**
 * 在线率
 * @param {string} deviceId
 * @returns
 */
export function getMonitorState(deviceId) {
  return request(`${serviceName}/dust/monitor/state?deviceId=${deviceId}`, {
    method: 'GET',
  });
}
