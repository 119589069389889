<template>
  <div
    class="h-full flex flex-col items-center justify-center"
    :class="$style.cardWrap"
  >
    <div class="grid grid-cols-2" :class="$style.dotWrap">
      <div class="flex justify-center">
        国控点PM10:{{ formatText(data.npm10) }}µg/m³
      </div>
      <div class="flex justify-center">
        国控点PM2.5:{{ formatText(data.npm25) }}µg/m³
      </div>
    </div>

    <div class="grid grid-cols-2" :class="$style.gridWrap">
      <div class="flex items-center gap-rem-8">
        <img :class="$style.icon" src="@/assets/images/theme/pm10.svg" />
        <div
          class="flex flex-col items-center justify-center"
          :class="$style.valueWrap"
        >
          <span>PM10</span>
          <span
            :class="[
              $style.textshadow,
              data.pm10 > 0
                ? data.pm10 > data.npm10
                  ? $style.red
                  : $style.green
                : '',
            ]"
            >{{ formatText(data.pm10) }}µg/m³</span
          >
        </div>
        <div :class="$style.upDown">
          <img
            v-if="data.pm10 > data.npm10"
            :class="$style.tipIcon"
            src="@/assets/images/theme/dn-icon.svg"
          />
          <img
            v-if="data.pm10 < data.npm10"
            :class="$style.tipIcon"
            src="@/assets/images/theme/up-icon.svg"
          />
        </div>
      </div>
      <div class="flex items-center gap-rem-8">
        <img :class="$style.icon" src="@/assets/images/theme/pm2.5.svg" />
        <div
          class="flex flex-col items-center justify-center"
          :class="$style.valueWrap"
        >
          <span>PM2.5</span>
          <span
            :class="[
              $style.textshadow,
              data.pm25 > 0
                ? data.pm25 > data.npm25
                  ? $style.red
                  : $style.green
                : '',
            ]"
            >{{ formatText(data.pm25) }}µg/m³</span
          >
        </div>
        <div :class="$style.upDown">
          <img
            v-if="data.pm25 > data.npm25"
            :class="$style.tipIcon"
            src="@/assets/images/theme/dn-icon.svg"
          />
          <img
            v-if="data.pm25 < data.npm25"
            :class="$style.tipIcon"
            src="@/assets/images/theme/up-icon.svg"
          />
        </div>
      </div>
      <div class="flex items-center gap-rem-8">
        <img :class="$style.icon" src="@/assets/images/theme/tsp.svg" />
        <div
          class="flex flex-col items-center justify-center"
          :class="$style.valueWrap"
        >
          <span>TSP</span>
          <span :class="$style.textshadow"
            >{{ formatText(data.particulates) }}µg/m³</span
          >
        </div>
        <div :class="$style.upDown"></div>
      </div>
      <div class="flex items-center gap-rem-8">
        <img :class="$style.icon" src="@/assets/images/theme/qy.svg" />
        <div
          class="flex flex-col items-center justify-center"
          :class="$style.valueWrap"
        >
          <span>气压</span>
          <span :class="$style.textshadow"
            >{{ formatText(data.pressure) }}Pa</span
          >
        </div>
        <div :class="$style.upDown"></div>
      </div>
    </div>

    <div class="grid grid-cols-5" :class="$style.labelWrap">
      <div class="flex flex-col items-center">
        <span :class="$style.textshadow">{{ data.temperature }}℃</span>
        <span>温度</span>
      </div>
      <div class="flex flex-col items-center">
        <span :class="$style.textshadow">{{ formatText(data.humidity) }}%</span>
        <span>湿度</span>
      </div>
      <div class="flex flex-col items-center">
        <span :class="$style.textshadow">{{
          exchangeDirection(data.windDirection)
        }}</span>
        <span>风向</span>
      </div>
      <div class="flex flex-col items-center">
        <span :class="$style.textshadow"
          >{{ formatText(data.windSpeed) }}m/s</span
        >
        <span>风速</span>
      </div>
      <div class="flex flex-col items-center">
        <span :class="$style.textshadow">{{ formatText(data.noise) }}dB</span>
        <span>噪音</span>
      </div>
    </div>
    <!-- <div class="">
      最后更新时间：{{
        +data.renewTime > 0
          ? formatText(moment(data.renewTime).format('YYYY-MM-DD HH:mm:ss'))
          : '-'
      }}
    </div> -->
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import moment from 'moment';
import { swindDirection } from '@/views/screen/utils';

@Component({
  components: {},
})
export default class DustmonitoringData extends Vue {
  @Prop({ type: Object, default: () => {} }) data;

  moment = moment;
  formatText(text) {
    return text === -1 ? '-' : text ?? '-';
  }

  exchangeDirection(v) {
    if (v < 0) return '-';
    return swindDirection(v);
  }
}
</script>
<style lang="less" module>
.textshadow {
  color: #fff;
  text-shadow: 0px 0px 7px #1f69c8, 0px 0px 10px #7db6ff;
  font-size: 0.18rem;
  font-weight: 700;
}
.red {
  text-shadow: 0px 0px 7px #c81f1f, 0px 0px 10px #ff7d7d;
}
.green {
  text-shadow: 0px 0px 7px #23ab1c, 0px 0px 10px #7dff90;
}
.gridWrap {
  gap: 0.15rem 0.5rem;
  margin-top: -0.18rem;
  .valueWrap {
    width: 0.96rem;
  }
}
.upDown {
  width: 0.16rem;
}
.labelWrap {
  gap: 0.08rem;
  div {
    width: 0.72rem;
  }
}
.cardWrap {
  font-size: 0.12rem;
  color: #d8e1f3;
  row-gap: 0.22rem;
  user-select: none;
}

.dotWrap {
  width: 80%;
}
</style>
