<template>
  <div>
    <div class="flex gap-8" style="margin-bottom: 0.1rem;">
      <a-select
        style="width: 2rem;"
        placeholder="请选择设备"
        :options="deviceOption"
        v-model="params.deviceId"
        allowClear
      ></a-select>
      <a-range-picker @change="onChange" />
    </div>
    <div style="height: 50vh">
      <PaginationTable
        ref="tableRef"
        row-key="pkId"
        :api="api"
        :columns="columns"
        :scroll="{ x: false, y: 'max-content' }"
        :paginationConfig="{
          size: 'small',
          showQuickJumper: false,
        }"
      />
    </div>
  </div>
</template>

<script>
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import EmptyContent from '@/components/empty-content/empty-content.vue';
import AsyncSelect from '@/components/async-select';
import PaginationTable from '@/components/pagination-table';
import { querySprayList } from '@/services/screen/jiangsu/green-construction';
import { nowSize } from '@/utils/common';
import moment from 'moment';

@Component({ components: { EmptyContent, AsyncSelect, PaginationTable } })
export default class SprayTableModal extends Vue {
  @Prop({ required: true }) projectId;
  @Prop({ required: true }) deviceOption;
  params = {
    deviceId: undefined,
    startTime: undefined,
    endTime: undefined,
  };
  @Watch('params', { deep: true })
  paramsChange() {
    this.$refs.tableRef.search();
  }
  async api({ current, size }) {
    const res = await querySprayList({
      query: {
        current,
        size,
      },
      projectId: this.projectId,
      ...this.params,
    });
    return res;
  }
  columns = [
    {
      title: '设备名称',
      dataIndex: 'deviceName',
      width: nowSize(200),
    },
    {
      title: '设备码',
      dataIndex: 'deviceCode',
      width: nowSize(200),
    },
    {
      title: '喷淋开始时间',
      dataIndex: 'sprayStartTime',
      width: nowSize(200),
      customRender: text =>
        text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-',
    },
    {
      title: '喷淋结束时间',
      width: nowSize(200),
      dataIndex: 'sprayEndTime',
      customRender: text =>
        text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-',
    },
    {
      title: '喷淋时长',
      dataIndex: 'sprayMinute',
      width: nowSize(100),
      customRender: text => (text === -1 ? '-' : `${text}分0秒`),
    },
    {
      title: '喷淋方式',
      dataIndex: 'sprayWay',
      width: nowSize(100),
    },
  ];

  onChange([value1, value2]) {
    this.params.startTime = value1
      ? moment(value1)
          .startOf('day')
          .valueOf()
      : value1;
    this.params.endTime = value2
      ? moment(value2)
          .endOf('day')
          .valueOf()
      : value2;
  }
}
</script>

<style lang="less" module></style>
