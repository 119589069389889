import request from '../request';

const serviceName = '/smart-site/screen/green';

// 获取环境警报统计
export function environmentalAlarmStatistics(projectId, days) {
  return request(`${serviceName}/alarm/stat/dust/${projectId}/${days}`, {
    method: 'GET',
  });
}

// 获取环境警报记录
export function environmentalAlarmRecord(data) {
  return request(`${serviceName}/alarm/dust/record/list`, {
    method: 'POST',
    body: data,
  });
}

// 获取喷淋警报统计
export function sprayAlarmStatistics(projectId, days) {
  return request(`${serviceName}/alarm/stat/spray/${projectId}/${days}`, {
    method: 'GET',
  });
}

// 获取喷淋警报记录
export function sprayAlarmRecord(data) {
  return request(`${serviceName}/alarm/spray/record/list`, {
    method: 'POST',
    body: data,
  });
}

// 获取环境警报记录
export function sprayRecordList(data) {
  return request(`${serviceName}/sp/record/list`, {
    method: 'GET',
    body: data,
  });
}

// 获取喷淋统计
export function sprayStatistics(data) {
  return request(`${serviceName}/sp/statistics`, {
    method: 'GET',
    body: data,
  });
}

// 获取环境统计
export function environmentStatistics(data) {
  return request(`${serviceName}/statistics/environmental`, {
    method: 'GET',
    body: data,
  });
}

// 获取环境监测
export function environmentalMonitoring(projectId) {
  return request(`${serviceName}/list/${projectId}`, {
    method: 'GET',
  });
}
