import { render, staticRenderFns } from "./wran-table-modal.vue?vue&type=template&id=5faaec1a&"
import script from "./wran-table-modal.vue?vue&type=script&lang=js&"
export * from "./wran-table-modal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports