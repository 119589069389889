<template>
  <div :class="$style.wrap">
    <div class="grid grid-cols-3 grid-rows-8" :class="[$style.gridGap]">
      <div class="row-span-3">
        <LayoutCard title="扬尘监控" :class="$style.card">
          <template #action>
            <a-select
              :options="dustMonitoringOption"
              v-model="dustMonitoringValue"
              @change="dustMonitoringChange"
            />
          </template>
          <template #titleExtend>
            <span :class="$style.titleExtend"
              >今日在线率：{{ onlineRate }}%</span
            >
          </template>
          <!-- <videoPlayer
            :size="{ width: '100%', height: '100%' }"
            :url="dustMonitoringUrl"
          /> -->
          <VideoView
            v-if="videoConfig"
            :url="videoConfig.url"
            :accessToken="videoConfig.token"
            ref="videoRef"
            :showOperation="false"
          ></VideoView>
        </LayoutCard>
      </div>
      <div class="row-span-3">
        <LayoutCard :class="$style.card" title="扬尘监测数据">
          <template #action>
            <LayoutSelect
              :options="deviceOption"
              :value="selectDeviceId"
              style="width: 1.9rem"
              @change="changeDeviceSelect"
            ></LayoutSelect>
          </template>

          <template #titleExtend>
            <span :class="$style.titleExtend">
              最后更新时间：{{
                +dustData?.lastTime > 0
                  ? moment(dustData.lastTime).format('YYYY-MM-DD HH:mm:ss')
                  : '-'
              }}</span
            >
          </template>

          <div class="h-full overflow-hidden" style="padding: 0.16rem">
            <DustmonitoringData :data="dustData" />
          </div>
        </LayoutCard>
      </div>
      <div class="row-span-3">
        <LayoutCard :class="$style.card" title="预警/报警趋势">
          <template #action>
            <a-select
              :options="dateList"
              v-model="warningTrendSelectValue"
              @change="getWarningTrendData"
            />
          </template>

          <div class="h-full overflow-hidden" style="padding: 0.16rem">
            <LineChart
              :chart-data="warningTrendLineOption"
              :show-legend="true"
              :xInterValType="
                warningTrendSelectValue === 'CURRENT_DAY' ? 'hour' : 'day'
              "
              :interval="
                warningTrendSelectValue === 'CURRENT_DAY' ? { interval: 3 } : {}
              "
            />
          </div>
        </LayoutCard>
      </div>
      <div class="row-span-3">
        <LayoutCard
          :title="['预警信息', '报警信息']"
          @changeTitle="changeTitle"
        >
          <ScrollTable
            :columns="errorAndWarnInfoTable.columns"
            :data="errorAndWarnInfoTable.data"
            @cellClick="openWarnModal('WARN')"
          ></ScrollTable>
          <ScrollTable
            :columns="errorAndWarnInfoTable.columns"
            :data="errorAndWarnInfoTable.data"
            @cellClick="openWarnModal('ERROR')"
          ></ScrollTable>
        </LayoutCard>
      </div>
      <div class="row-span-3">
        <LayoutCard :class="$style.card" title="扬尘监测值趋势">
          <template #action>
            <a-select
              :options="dateList"
              v-model="dustTrendSelectValue"
              @change="getDustTrendData"
            />
          </template>

          <div class="h-full overflow-hidden" style="padding: 0.16rem">
            <LineChart
              :isSmooth="false"
              symbol="round"
              :chart-data="dustTrendLineOption"
              :show-legend="true"
              :xInterValType="
                dustTrendSelectValue === 'CURRENT_DAY' ? 'hour' : 'day'
              "
              :interval="
                dustTrendSelectValue === 'CURRENT_DAY' ? { interval: 3 } : {}
              "
            />
          </div>
        </LayoutCard>
      </div>
      <div class="row-span-3">
        <LayoutCard :class="$style.card" title="扬尘预/报警统计">
          <template #action>
            <a-select
              :options="dustStatisticsSelectOption"
              v-model="dustStatisticsSelect"
              @change="val => getDustStatisticsData(val)"
            />
          </template>
          <div class="h-full overflow-hidden" style="padding: 0.16rem">
            <BarChart
              :chartData="dustStatisticsBarOption"
              chartUnit="次"
              :barWidth="31"
            ></BarChart>
          </div>
        </LayoutCard>
      </div>
      <div class="col-span-3 row-span-2">
        <LayoutCard :class="$style.card" title="喷淋记录/分析">
          <template #action>
            <LayoutSelect
              :options="sprayAssaySelectOption"
              :defaultValue="sprayAssaySelect"
              @change="sprayAssaySelectChange"
            ></LayoutSelect>
          </template>

          <div class="p-8 h-full">
            <div :class="$style.cardBg" class="h-full">
              <div class="grid grid-cols-5 h-full overflow-hidden">
                <div class="overflow-hidden col-span-2">
                  <ScrollTable
                    :columns="sprayRecording.columns"
                    :data="sprayRecording.data"
                    @cellClick="openSprayModal"
                  ></ScrollTable>
                </div>
                <div class="overflow-hidden col-span-3">
                  <div class="grid grid-cols-2 h-full overflow-hidden">
                    <PieChart
                      :colorArray="['#FEBD7D', '#37D7DB']"
                      :chartData="triggerTypePieOption"
                      :legendItemGap="40"
                      :labelOption="labelOption"
                    ></PieChart>

                    <PieChart
                      :colorArray="['#FEBD7D', '#FFFFFF', '#37D7DB', '#F3FD7D']"
                      :chartData="warningPieOption"
                      :labelOption="labelOption2"
                    ></PieChart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LayoutCard>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import LineChart from '@/views/screen/e-charts/line-chart';
import BarsZoom from '@/views/screen/e-charts/bars-zoom';
import AlarmTable from '@/views/screen/components/alarm-table.vue';
import { createModal } from '@triascloud/x-components';
import { sprayStatistics } from '@/services/screen/green-construction';
import { LayoutTabs, LayoutCard, LayoutSelect } from '../../layout';
import ScrollTable from '../../components/scroll-table.vue';
import videoPlayer from '@/components/video-player';
import BarChart from '@/views/screen/e-charts/common-bar.vue';
import PieChart from '../../e-charts/pie-chart.vue';
import {
  getGreenDustData,
  getGreenDustTrend,
  getGreenWarningTrend,
  getGreenDustStatistics,
  querySprayList,
  queryGreenWranInfo,
  getGreenSprayAssay,
  getMonitorState,
} from '@/services/screen/jiangsu/green-construction';
import { nowSize } from '@/utils/common';
import DustmonitoringData from './components/dust-monitoring-data.vue';
import moment from 'moment';
import SprayTableModal from './components/spray-table-modal.vue';
import WranTableModal from './components/wran-table-modal.vue';
import { deviceTypeEnum } from '@/enum/device';
import {
  // commonDeviceList,
  getMonitoringList,
} from '@/services/screen/jiangsu/common';
import { getDeviceListsByCode } from '@/services/screen/jiangsu/labor';
import { swindDirection } from '../../utils/index';
import VideoView from '@/components/video-view';
import { getLiveAddress } from '@/services/device-manage/video-surveillance';

@Component({
  components: {
    LineChart,
    BarsZoom,
    AlarmTable,
    LayoutCard,
    LayoutTabs,
    LayoutSelect,
    ScrollTable,
    videoPlayer,
    BarChart,
    PieChart,
    DustmonitoringData,
    VideoView,
  },
})
export default class GreenConstructionIndex extends Vue {
  deviceGroup = deviceTypeEnum.DUST.deviceGroup;
  deviceType = deviceTypeEnum.DUST.deviceType;
  test = true;
  moment = moment;
  get projectId() {
    return this.$store.state.screen.projectId;
  }

  alarmTrendChartData = [];
  alarmStatisticsChartData = [];

  dateList = [
    {
      label: this.$t('screen.currentDay'),
      value: 'CURRENT_DAY',
    },
    {
      label: this.$t('screen.sevenDay'),
      value: 'SEVEN_DAY',
    },
    {
      label: this.$t('screen.thirtyDay'),
      value: 'THIRTY_DAY',
    },
  ];

  // 喷淋统计
  barChartData = [];
  async getSprayStatistic(idx = 0) {
    const days = ['SEVEN_DAY', 'THIRTY_DAY'];
    this.xData = [];
    const form = {
      idxProjectId: this.projectId,
      searchDay: days[idx],
    };
    try {
      const { times, dotDataList } = await sprayStatistics(form);
      if (times.length) {
        this.barChartData = [
          {
            time: times,
            data: dotDataList.map((item, i) => ({
              name: item.tag,
              type: item.tag === '喷淋次数' ? 'line' : 'bar',
              yAxisIndex: item.tag === '喷淋次数' ? 1 : 0,
              data: item.values.map(e => ({
                value: e === -1 ? 0 : e,
                unit: ['分钟', '次'][i],
              })),
            })),
          },
        ];
      } else {
        this.barChartData = [];
      }
    } catch {
      return false;
    }
  }
  changeSprayTab(idx) {
    this.getSprayStatistic(idx);
  }

  // 预警/报警趋势
  warningTrendData = {};
  warningTrendSelectValue = 'SEVEN_DAY';
  get warningTrendLineOption() {
    const { times = [], dataList = [] } = this.warningTrendData;
    return [
      {
        time: times,
        data: dataList?.map(item => ({
          name: item.tag,
          data: item.values.map(value => ({
            value,
            unit: '次',
          })),
        })),
      },
    ];
  }
  async getWarningTrendData() {
    const res = await getGreenWarningTrend(
      this.projectId,
      this.warningTrendSelectValue,
    );
    this.warningTrendData = res;
  }

  // 扬尘监测值趋势
  dustTrendData = {
    times: [],
    dataList: [],
  };
  dustTrendSelectValue = 'CURRENT_DAY';
  get dustTrendLineOption() {
    const { times, dataList } = this.dustTrendData;
    const unitMap = {
      'PM 2.5': 'µg/m³',
      'PM 10': 'µg/m³',
      '噪声': 'dB',
      '温度': '°C',
      '湿度': '%',
      '风速': 'm/s',
      '风向': '',
      '颗粒物': 'µg/m³',
      '气压': 'Pa',
    };
    return [
      {
        time: times,
        data: dataList?.map(item => ({
          name: item.tag,
          data: item.values.map(value => ({
            value: +value === -1 ? 0 : value,
            format: text => {
              if (item.tag === '风向') {
                return +text >= 0 ? swindDirection(text) : '';
              } else {
                return text + unitMap[item.tag];
              }
            },
          })),
        })),
      },
    ];
  }
  async getDustTrendData(days = 'CURRENT_DAY') {
    const res = await getGreenDustTrend(this.projectId, days);
    this.dustTrendData = res;
  }

  // 扬尘预/报警统计
  dustStatisticsSelectOption = [
    {
      label: this.$t('screen.currentDay'),
      value: 'CURRENT_DAY',
    },
    {
      label: this.$t('screen.sevenDay'),
      value: 'SEVEN_DAY',
    },
    {
      label: this.$t('screen.thirtyDay'),
      value: 'THIRTY_DAY',
    },
  ];
  dustStatisticsData = [];
  dustStatisticsSelect = 'SEVEN_DAY';
  get dustStatisticsBarOption() {
    const dataList = this.dustStatisticsData;
    return dataList.map(item => ({
      name: item.subTypeName,
      value: +item.num >= 0 ? item.num : 0,
    }));
  }
  async getDustStatisticsData() {
    this.dustStatisticsData = await getGreenDustStatistics(
      this.projectId,
      this.dustStatisticsSelect,
    );
  }

  // 扬尘监测数据
  deviceOption = [];
  deviceDataList = [];
  selectDeviceId = '';
  async getDeviceList() {
    try {
      this.deviceDataList = await getDeviceListsByCode({
        projectId: this.projectId,
        deviceGroup: this.deviceType,
      });
      if (this.deviceDataList.length) {
        this.deviceOption = this.deviceDataList.map(item => ({
          label: item.deviceName,
          value: item.pkId,
        }));
        this.selectDeviceId = this.deviceDataList[0].pkId;
      }
    } catch {
      return false;
    }
  }
  dustData = {};
  async getDustData() {
    const res = await getGreenDustData(this.projectId, this.selectDeviceId);
    this.dustData = res ?? {};
  }
  changeDeviceSelect(val) {
    this.selectDeviceId = val;
    this.getDustData();
  }

  // 喷淋分析
  sprayAssaySelect = 'CURRENT_DAY';
  sprayAssaySelectOption = [
    {
      label: this.$t('screen.currentDay'),
      value: 'CURRENT_DAY',
    },
    {
      label: this.$t('screen.thirtyDay'),
      value: 'THIRTY_DAY',
    },
  ];
  sprayAssayData = {
    errorNum: 0,
    manuallyNum: 0,
    pm10ErrorNum: 0,
    pm10WarnNum: 0,
    pm25ErrorNum: 0,
    pm25WarnNum: 0,
  };
  async getSprayAssay() {
    const res = await getGreenSprayAssay(this.projectId, this.sprayAssaySelect);
    Object.assign(this.sprayAssayData, res);
  }
  sprayAssaySelectChange(val) {
    this.sprayAssaySelect = val;
    this.getSprayAssay();
  }
  // 喷淋分析-图1
  get triggerTypePieTotal() {
    return this.triggerTypePieOption.reduce((a, b) => a + b.value, 0);
  }
  get triggerTypePieOption() {
    return [
      { value: this.sprayAssayData.errorNum, name: '报警触发' },
      { value: this.sprayAssayData.manuallyNum, name: '手动触发' },
    ];
  }
  get labelOption2() {
    return {
      show: true,
      position: 'center',
      formatter: `{a|预/报警次数}\n{b|${this.warningPieTotal}}`,
      rich: {
        a: {
          color: '#9AC0D9',
          fontSize: nowSize(12),
        },
        b: {
          color: '#fff',
          textShadowColor: '#7DB6FF',
          textShadowBlur: 8,
          fontSize: nowSize(18),
          padding: [nowSize(8), 0, 0, 0],
        },
      },
    };
  }

  // 喷淋分析-图2--start
  get warningPieTotal() {
    return this.warningPieOption.reduce((a, b) => a + b.value, 0);
  }
  get warningPieOption() {
    return [
      { value: this.sprayAssayData.pm25WarnNum, name: 'PM2.5预警' },
      { value: this.sprayAssayData.pm25ErrorNum, name: 'PM2.5报警' },
      { value: this.sprayAssayData.pm10WarnNum, name: 'PM10预警' },
      { value: this.sprayAssayData.pm10ErrorNum, name: 'PM10报警' },
    ];
  }
  get labelOption() {
    return {
      show: true,
      position: 'center',
      formatter: `{a|联动次数}\n{b|${this.triggerTypePieTotal}}`,
      rich: {
        a: {
          color: '#9AC0D9',
          fontSize: nowSize(12),
        },
        b: {
          color: '#fff',
          textShadowColor: '#7DB6FF',
          textShadowBlur: 8,
          fontSize: nowSize(18),
          padding: [nowSize(8), 0, 0, 0],
        },
      },
    };
  }
  // 喷淋分析-图2--end

  // 喷淋记录--start
  sprayRecording = {
    columns: [
      {
        title: '设备名称',
        key: 'deviceName',
        width: '25%',
      },
      {
        title: '喷淋开始时间',
        key: 'sprayStartTime',
        width: '25%',
        format: text =>
          text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-',
      },
      {
        title: '喷淋结束时间',
        key: 'sprayEndTime',
        width: '25%',

        format: text =>
          text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-',
      },
      {
        title: '喷淋方式',
        key: 'sprayWay',
      },
    ],
    data: [],
  };
  async getSprayList() {
    const { records } = await querySprayList({
      query: {
        current: 1,
        size: 5,
      },
      projectId: this.projectId,
    });
    this.sprayRecording.data = records;
  }
  async openSprayModal() {
    await createModal(
      () => (
        <SprayTableModal
          projectId={this.projectId}
          deviceOption={this.deviceOption}
        />
      ),
      {
        wrapClassName: 'screen-modal',
        width: nowSize(1300),
        title: '喷淋记录',
      },
    );
  }
  // 喷淋记录--end

  // 获取报警/预警信息
  errorAndWarnInfoTable = {
    columns: [
      {
        title: '设备名称',
        key: 'deviceName',
        width: '33%',
      },
      {
        title: '类型',
        key: 'subTypeName',
        width: '35%',
      },
      {
        title: '预警时间',
        key: 'alarmTime',
        format: text =>
          text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-',
      },
    ],
    data: [],
  };
  async getErrorOrWarnInfo(type) {
    const { records } = await queryGreenWranInfo({
      query: {
        current: 1,
        size: 15,
      },
      type,
      projectId: this.projectId,
    });
    this.errorAndWarnInfoTable.data = records;
  }
  async openWarnModal(type) {
    await createModal(
      () => (
        <WranTableModal
          projectId={this.projectId}
          deviceOption={this.deviceOption}
          type={type}
        />
      ),
      {
        wrapClassName: 'screen-modal',
        width: nowSize(1300),
        title: {
          WARN: '预警信息',
          ERROR: '报警信息',
        }[type],
      },
    );
  }
  infoTitleIndex = 0;
  changeTitle(index) {
    if (this.infoTitleIndex === index) {
      return;
    }
    this.infoTitleIndex = index;
    const type = index === 0 ? 'WARN' : 'ERROR';
    this.getErrorOrWarnInfo(type);
  }
  // end

  // 监控
  dustMonitoringList = [];
  get videoConfig() {
    return this.liveAddressToken[this.dustMonitoringValue];
  }
  dustMonitoringValue = '';
  get dustMonitoringOption() {
    return this.dustMonitoringList.map(({ deviceName, pkId }) => ({
      label: deviceName,
      value: pkId,
    }));
  }
  liveAddressToken = {};
  async getLiveAddress() {
    if (!this.dustMonitoringValue) return;
    if (this.liveAddressToken[this.dustMonitoringValue]) return;
    const res = await getLiveAddress({
      deviceId: this.dustMonitoringValue,
      channelNo: '',
    });
    this.$set(this.liveAddressToken, this.dustMonitoringValue, res);
    return res;
  }
  async getDustMonitoringList() {
    const res = await getMonitoringList({
      projectId: this.projectId,
      deviceGroup: this.deviceGroup,
    });
    this.dustMonitoringList = res;
    this.dustMonitoringValue = res[0]?.pkId;
    this.getLiveAddress();
  }
  async dustMonitoringChange() {
    this.getMonitorState();
    await this.getLiveAddress();
    await this.$nextTick();
    this.$refs.videoRef.changeVideoLink(this.videoConfig.url);
  }
  onlineRate = 0;
  async getMonitorState() {
    if (!this.dustMonitoringValue) return;
    this.onlineRate = (await getMonitorState(this.dustMonitoringValue)) ?? 0;
  }
  // end

  async init() {
    this.getWarningTrendData();
    this.getDustTrendData();
    this.getDustData();
    this.getDustStatisticsData();
    this.getSprayList();
    this.getErrorOrWarnInfo('WARN');
    this.getSprayAssay();
    await this.getDustMonitoringList();
    this.getMonitorState();
  }

  async created() {
    await this.getDeviceList();
    this.init();
  }
}
</script>

<style lang="less" module>
@import '@/assets/css/mixin.less';
.wrap {
  height: 100%;
  overflow: hidden;
}

.gridGap {
  height: 100%;
  .px2rem(gap, 16);
}

.cardBg {
  // background: linear-gradient(
  //   270deg,
  //   rgba(118, 197, 255, 0.3) 0%,
  //   rgba(118, 197, 255, 0.06) 41%,
  //   rgba(118, 197, 255, 0.06) 60%,
  //   rgba(118, 197, 255, 0.3) 100%
  // );
}
.titleExtend {
  color: #d8e1f3;
  font-size: 0.12rem;
  padding-left: 0.05rem;
}
</style>
