<template>
  <div :class="$style.wrapper">
    <table :class="$style.tableContainer">
      <thead v-if="tableData.length > 0">
        <tr :class="$style.tableTitle" v-if="showTitle">
          <th v-for="item in columns" :key="item.dataIndex">
            {{ item.title }}
          </th>
        </tr>
      </thead>

      <tbody v-if="tableData.length > 0">
        <tr
          :class="$style.tableContent"
          v-for="(item, index) in tableData"
          :key="index"
        >
          <td
            v-for="tdItem in columns"
            :key="tdItem.dataIndex"
            :style="{ width: tdItem.width }"
          >
            <template v-if="tdItem.type === 'index'">{{ index + 1 }}</template>
            <template v-else-if="tdItem.type === 'img'">
              <oss-image
                v-if="item[tdItem.dataIndex].length"
                :ossPath="item[tdItem.dataIndex]"
                :class="$style.tableImg"
                :imgNameList="imgNameList"
              />
            </template>
            <template v-else-if="tdItem.type === 'time'">{{
              item[tdItem.dataIndex]
                ? dayjs(item[tdItem.dataIndex]).format('YYYY-MM-DD HH:mm:ss')
                : '--'
            }}</template>
            <template v-else-if="tdItem.unit">{{
              item[tdItem.dataIndex] >= 0
                ? item[tdItem.dataIndex] + tdItem.unit
                : '--'
            }}</template>
            <template v-else>{{ item[tdItem.dataIndex] || '--' }}</template>
          </td>
        </tr>
      </tbody>
      <EmptyContent v-if="tableData.length === 0" />
    </table>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import EmptyContent from '@/components/empty-content';
import OssImage from './oss-image.vue';

@Component({
  components: {
    EmptyContent,
    OssImage,
  },
})
export default class ScreenAlarmTable extends Vue {
  @Prop({ type: Array, default: () => [], required: true }) tableData;
  @Prop({ type: Array, default: () => [], required: true }) columns;
  @Prop({ type: Boolean, default: true }) showTitle;
  @Prop({ type: Array, default: () => [] }) imgNameList;
}
</script>

<style lang="less" module>
.wrapper {
  padding: 0 0.16rem 0.18rem;
}
.tableContainer {
  width: 100%;
  height: 100%;
  position: relative;
  .tableTitle {
    font-size: 0.14rem;
    color: #9ac0d9;
    text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.302);

    th {
      padding-bottom: 0.16rem;
      text-align: center;
      white-space: nowrap;
    }
  }
  .tableContent {
    font-size: 0.12rem;
    color: var(--font);
    border-bottom: 1px solid var(--border);
    // display: flex;
    td {
      // flex: 1;
      text-align: center;
      white-space: nowrap;
      height: 0.24rem;
      line-height: 0.24rem;
      .tableImg {
        img {
          width: 35px;
          margin-right: 2px;
        }
      }
    }

    &:nth-of-type(odd) {
      background: linear-gradient(
        270deg,
        #76c5ff94 0%,
        rgba(118, 197, 255, 0.06) 41%,
        rgba(118, 197, 255, 0.06) 60%,
        #76c5ff94 100%
      );
    }
  }
}
</style>
