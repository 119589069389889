<template>
  <div>
    <div class="flex gap-8" style="margin-bottom: 0.1rem;">
      <LayoutSelect
        placeholder="请选择设备"
        :options="deviceOption"
        :defaultValue="params.deviceId"
        @change="selectChange"
        style="width: 2rem;"
        allowClear
      ></LayoutSelect>
      <a-range-picker @change="onChange" />
    </div>
    <div style="height: 50vh">
      <PaginationTable
        ref="tableRef"
        row-key="pkId"
        :api="api"
        :columns="columns"
        :scroll="{ x: false, y: 'max-content' }"
        :paginationConfig="{
          size: 'small',
          showQuickJumper: false,
        }"
      />
    </div>
  </div>
</template>

<script>
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import EmptyContent from '@/components/empty-content/empty-content.vue';
import AsyncSelect from '@/components/async-select';
import PaginationTable from '@/components/pagination-table';
import { queryGreenWranInfo } from '@/services/screen/jiangsu/green-construction';
import { nowSize } from '@/utils/common';
import moment from 'moment';
import { LayoutSelect } from '@/views/screen/layout';

@Component({
  components: { EmptyContent, AsyncSelect, PaginationTable, LayoutSelect },
})
export default class WranTableModal extends Vue {
  @Prop({ required: true }) projectId;
  @Prop({ required: true }) deviceOption;
  @Prop({ required: true, type: String }) type;
  params = {
    deviceId: undefined,
    startTime: undefined,
    endTime: undefined,
  };
  @Watch('params', { deep: true })
  paramsChange() {
    this.$refs.tableRef.search();
  }
  async api({ current, size }) {
    const res = await queryGreenWranInfo({
      query: {
        current,
        size,
      },
      projectId: this.projectId,
      type: this.type,
      ...this.params,
    });
    return res;
  }
  columns = [
    {
      title: '设备名称',
      dataIndex: 'deviceName',
      width: nowSize(180),
    },
    {
      title: '设备码',
      dataIndex: 'deviceCode',
      width: nowSize(170),
    },
    {
      title: '类型',
      dataIndex: 'subTypeName',
      width: nowSize(150),
    },
    {
      title: '阈值',
      width: nowSize(100),
      dataIndex: 'threshold',
    },
    {
      title: '触发数值',
      dataIndex: 'alarmValue',
      width: nowSize(100),
      customRender: val => (val === -1 ? '-' : val),
    },
    {
      title: '预警时间',
      dataIndex: 'alarmTime',
      width: nowSize(200),
      customRender: text =>
        text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-',
    },
  ];

  onChange([value1, value2]) {
    this.params.startTime = value1
      ? moment(value1)
          .startOf('day')
          .valueOf()
      : value1;
    this.params.endTime = value2
      ? moment(value2)
          .endOf('day')
          .valueOf()
      : value2;
  }

  selectChange(val) {
    this.params.deviceId = val;
  }
}
</script>

<style lang="less" module></style>
